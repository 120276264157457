import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { callTwitchExchangeFunction } from "../../../API/callTwitchExchangeFunction";
import { fetchTwitchUserData } from "../../../API/fetchTwitchUserData"; // Assuming you have this function
import { db } from "../../../firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import { useAuth } from "../../../context/AuthContext";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";

const TwitchAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      callTwitchExchangeFunction(code)
        .then(async (response: any) => {
          const accessToken = response?.access_token;
          const twitchUserData = await fetchTwitchUserData(accessToken);

          if (currentUser?.uid) {
            console.log("TwitchUserData", twitchUserData);

            const userRef = doc(db, "users", currentUser?.uid);
            await updateDoc(userRef, {
              twitchName: twitchUserData.login, // Replace with the appropriate property
              isTwitchUserAuthenticated: true,
            });

            navigate("/profile"); // Redirect to the user profile or another appropriate route
          }
        })
        .catch((error) => {
          console.error("Twitch exchange error:", error);
          // Optionally update user profile to set isTwitchUserAuthenticated as false
        });
    } else {
      navigate("/"); // Redirect back to a safe route
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate]);

  return <LoadingSpinner />;
};

export default TwitchAuth;
