import React from "react";

const About = () => {
  const content = {
    headline: "About Us",
    imageUrl: "/assets/images/peer-lucas.png", // Adjust the path as necessary
    smallImage: true,
    contentSections: [
      {
        headline: "",
        text: "Welcome to Sponsearly! We are Lucas and Peer, the founders and passionate gamers who met during our bachelor's studies in Berlin. After 12 years of friendship, we decided to leverage our skills and experiences to create a platform that bridges the gap between brands and content creators. Lucas pursued a master's degree in Logistics, while Peer specialized in Business Informatics.",
      },
      {
        headline: "",
        text: "At Sponsearly, we make it easy for brands and early-stage content creators to connect. Our mission is to streamline the process of finding and securing sponsorships, making it accessible for everyone. We offer a cutting-edge algorithm that identifies the best partnership opportunities, saving time and effort for both parties.",
      },
      {
        headline: "",
        text: "We are particularly proud of our innovative tool that tracks activities on Twitch, allowing brands to find the perfect match among content creators who are passionate about gaming. Whether you're a brand looking to promote gaming peripherals like keyboards or a creator aiming to grow your channel, Sponsearly is here to support you.",
      },
      {
        headline: "",
        text: "In addition to our professional pursuits, we also share a love for video games, which fuels our enthusiasm for this industry. We are excited to be a part of your journey and look forward to helping you succeed in the dynamic world of content creation and sponsorships.",
      },
    ],
  };

  return (
    <div className="max-w-6xl mx-auto my-8 p-4">
      <h1 className="text-4xl font-bold text-center mb-6">
        {content.headline}
      </h1>
      <div className="flex flex-col md:flex-row items-center mb-6">
        <div className="flex-1 flex justify-center md:justify-start mb-4 md:mb-0">
          <img
            src={content.imageUrl}
            alt={content.headline}
            className="max-w-full max-h-80 md:max-h-96 object-contain"
          />
        </div>
        <div className="flex-1 md:px-4">
          {content.contentSections.map((section, index) => (
            <div key={index} className="mb-6">
              <h2 className="text-2xl font-bold mb-2">{section.headline}</h2>
              <p className="text-lg">{section.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
