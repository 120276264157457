import { useEffect, useState } from "react";
import { db } from "../../../firebaseConfig";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import CreatorTable from "./Table/CreatorTable";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import { IUser } from "../../../interfaces/IUser";
import { useLocation } from "react-router-dom";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationLink,
} from "src/components/shadcn/Pagination";

export type SortType = {
  field: keyof IUser | null;
  direction: "asc" | "desc";
};

export type FilterValue = string | RangeFilter;
export interface RangeFilter {
  min: string;
  max: string;
}

export interface Filters {
  [key: string]: FilterValue;
}

const CreatorSearch = () => {
  const location = useLocation();
  const initialFilter = location.state?.initialFilter;

  const [creators, setCreators] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState<SortType>({ field: null, direction: "asc" });
  const [page, setPage] = useState(1);

  // Update the filters state type to use this new FilterValue type
  const [filters, setFilters] = useState<Filters>({});

  const pageSize = 20;

  const fetchCreators = async () => {
    setLoading(true);
    try {
      let q = query(
        collection(db, "users"),
        where("role", "==", "creator"),
        where("hasValidDisplayName", "==", true), // Ensure displayName exists and is not an empty string
        where("displayName", "!=", ""),
        ...(sort.field ? [orderBy(sort.field, sort.direction)] : []),
        limit(pageSize)
      );

      if (page > 1) {
        // Fetch the last document from the previous page to use as the starting point
        const previousPageEnd = query(
          collection(db, "users"),
          where("role", "==", "creator"),
          where("hasValidDisplayName", "==", true), // Ensure displayName exists and is not an empty string
          where("displayName", "!=", ""),
          ...(sort.field ? [orderBy(sort.field, sort.direction)] : []),
          limit((page - 1) * pageSize)
        );
        const documentSnapshots = await getDocs(previousPageEnd);
        const lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];

        // Adjust query to start after the last document from the previous page
        q = query(q, startAfter(lastVisible));
      }

      const querySnapshot = await getDocs(q);
      let fetchedCreators: IUser[] = [];
      querySnapshot.forEach((doc) => {
        fetchedCreators.push({ ...(doc.data() as IUser), id: doc.id });
      });

      fetchedCreators = fetchedCreators.filter((creator: IUser) =>
        Object.entries(filters).every(([key, filterValue]) => {
          // Skip filtering if the filter value is empty
          if (typeof filterValue === "string" && filterValue === "")
            return true;

          const fieldValue = creator[key as keyof IUser];

          // Handle range filters (followers_change and followers_total)
          if (key === "followers_change" || key === "followers_total") {
            if (
              typeof filterValue === "object" &&
              (filterValue.min !== undefined || filterValue.max !== undefined)
            ) {
              const min = filterValue.min
                ? parseFloat(filterValue.min)
                : -Infinity;
              const max = filterValue.max
                ? parseFloat(filterValue.max)
                : Infinity;

              let actualValue = 0;
              if (key === "followers_change") {
                if (
                  filterValue.min?.endsWith("%") ||
                  filterValue.max?.endsWith("%")
                ) {
                  actualValue = creator.followers_change ?? 0;
                } else {
                  actualValue = creator.followers_gained ?? 0;
                }
              } else if (key === "followers_total") {
                actualValue = creator.followers_total ?? 0;
              }

              return actualValue >= min && actualValue <= max;
            } else if (typeof filterValue === "string") {
              // Direct value filter with potential percentage for followers_change
              if (key === "followers_change" && filterValue.endsWith("%")) {
                const percentageValue = parseFloat(filterValue.slice(0, -1));
                const actualChange = creator.followers_change ?? 0;
                return actualChange === percentageValue;
              }
            }
          } else {
            // Handle other fields (e.g., string matches and array inclusion)
            if (typeof filterValue === "string") {
              if (typeof fieldValue === "string") {
                return fieldValue
                  .toLowerCase()
                  .includes(filterValue.toLowerCase());
              } else if (Array.isArray(fieldValue)) {
                return fieldValue.some((item) =>
                  item.label.toLowerCase().includes(filterValue.toLowerCase())
                );
              }
            }
          }

          return false; // Default case if none of the above conditions are met
        })
      );

      console.log("fetchedCreators", fetchedCreators);

      setCreators(fetchedCreators);
    } catch (error) {
      console.error("Error fetching creators:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (
    field: keyof IUser,
    value: string,
    rangePart?: "min" | "max"
  ) => {
    setFilters((prevFilters) => {
      // Check if we're dealing with a range part (min or max)
      if (rangePart) {
        // If dealing with a range, ensure the current filter is structured as a RangeFilter
        const currentFilter = prevFilters[field] || { min: "", max: "" }; // Provide default RangeFilter structure
        if (
          typeof currentFilter === "object" &&
          !Array.isArray(currentFilter) &&
          currentFilter !== null
        ) {
          // Update the specific part of the range (min or max)
          const updatedRange = { ...currentFilter, [rangePart]: value };
          return { ...prevFilters, [field]: updatedRange };
        } else {
          // This should not happen since rangePart implies we want a RangeFilter,
          // but it's here to satisfy TypeScript's type checking.
          return prevFilters;
        }
      } else {
        // If not dealing with a range, update the filter directly with the value
        return { ...prevFilters, [field]: value };
      }
    });
  };

  useEffect(() => {
    fetchCreators(); // Fetch first page on initial load
    // eslint-disable-next-line
  }, [sort, page, filters]);

  useEffect(() => {
    if (initialFilter?.favoriteCategories) {
      // Assuming you want to filter by displayName for example
      setFilters({ favoriteCategories: initialFilter.favoriteCategories });
    } else if (initialFilter?.favoriteProducts) {
      setFilters({ favoriteProducts: initialFilter.favoriteProducts });
    } else if (initialFilter?.audience) {
      setFilters({ audience: initialFilter.audience });
    }
    fetchCreators();
    // You should include fetchCreators in the dependency array only if it's guaranteed to be stable (not re-created on each render). If it's not stable, consider using useCallback or moving fetchCreators inside the effect.
  }, [initialFilter]); // Add other dependencies as needed

  const handleSort = (field: keyof IUser) => {
    setSort((prevSort) => ({
      field,
      direction:
        prevSort.field === field && prevSort.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  return (
    <div>
      <h2 className="font-bold ml-4 mt-4">
        Filter, sort and find creators you are interested in!
      </h2>{" "}
      {loading && <LoadingSpinner />}
      <CreatorTable
        isCreatorSearch={true}
        creators={creators}
        onSort={handleSort}
        onFilterChange={handleFilterChange}
        filters={filters}
        sort={sort}
      />
      <div className="flex justify-center mt-4">
        {/* Pagination Section */}
        <Pagination>
          <PaginationContent>
            {/* Previous Button */}
            <PaginationItem>
              <PaginationPrevious
                href="#"
                onClick={() => {
                  setPage((prev) => Math.max(prev - 1, 1));
                  // fetchCreators(); // Re-fetch data for previous page
                }}
                disabled={page === 1}
              />
            </PaginationItem>

            {/* Display Current Page */}
            <PaginationItem>
              <PaginationLink href="#" isActive={false}>
                {page}
              </PaginationLink>
            </PaginationItem>

            {/* Next Button */}
            <PaginationItem>
              <PaginationNext
                href="#"
                onClick={() => {
                  if (creators.length >= pageSize) {
                    setPage((prev) => prev + 1);
                    fetchCreators(); // Fetch the next page
                  }
                }}
                disabled={creators.length < pageSize} // Disable if no more data
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </div>
  );
};

export default CreatorSearch;
