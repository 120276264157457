import { Fragment, useEffect, useState } from "react";
import FAQ from "./components/FAQ/FAQ";
// import FeaturesSection from "./components/FeaturesSection/FeaturesSection";
import HeroSection from "./components/HeroSection/HeroSection";
// import Testimonials from "./components/Testimonials/Tesitmonials";
import Features from "./components/Features/Features";
import ContactForm from "./components/ContactForm/ContactForm";
import WelcomeModal from "../../components/WelcomeModal/WelcomeModal";

const LandingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");

    if (!hasVisited) {
      setIsModalOpen(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Fragment>
      <WelcomeModal isOpen={isModalOpen} onClose={closeModal} />

      <HeroSection />
      <div className="flex-grow ">
        {/* <FeaturesSection /> */}
        <Features />
        {/* <Testimonials /> */}
        <FAQ />
        <ContactForm />
      </div>
    </Fragment>
  );
};

export default LandingPage;
