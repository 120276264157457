import { ChangeEvent, useState } from "react";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";

export interface IArticle {
  id?: string;
  title: string;
  content: IArticleContent[];
  url: string;
}

export interface IArticleContent {
  text: string;
}

export interface IArticleLine {
  url: string;
  displayText: string;
}

const UploadArticle = () => {
  const [article, setArticle] = useState<IArticle>({
    title: "",
    url: "",
    content: [
      {
        text: "",
      },
    ],
  });

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setArticle({ ...article, title: e.target.value });
  };
  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setArticle({ ...article, url: e.target.value });
  };

  const handleTextChange = (
    index: number,
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newContent = [...article.content];
    newContent[index].text = e.target.value;
    setArticle({ ...article, content: newContent });
  };

  const handleAddParagraph = () => {
    setArticle({
      ...article,
      content: [...article.content, { text: "" }],
    });
  };

  const handleSubmit = async () => {
    console.log("Article data to submit:", article);

    try {
      const docRef = await addDoc(collection(db, "articles"), article);
      console.log("Document written with ID:", docRef.id);
      // Handle post-submit logic here, e.g., showing a success message or clearing the form
    } catch (e) {
      console.error("Error adding document: ", e);
      // Handle the error case, e.g., showing an error message
    }
  };

  const handleDeleteParagraph = (index: number) => {
    const newContent = article.content.filter((_, i) => i !== index);
    setArticle({ ...article, content: newContent });
  };

  return (
    <div className="flex-wrap mb-4">
      This below will be only seen by users who are AUTHOR.
      <div className="w-full px-3 mb-6">
        <label htmlFor="title" className="block text-sm font-bold mb-2">
          Title:
        </label>
        <input
          type="text"
          id="title"
          value={article.title}
          onChange={handleTitleChange}
          className="w-full px-3 py-2 border rounded text-secondaryText"
        />
      </div>
      <div className="w-full px-3 mb-6">
        <label htmlFor="url" className="block text-sm font-bold mb-2">
          URL: !IMPORTANT! Url must be something like /article. MUST start with
          / and must be unique
        </label>
        <input
          type="text"
          id="url"
          value={article.url}
          onChange={handleUrlChange}
          className="w-full px-3 py-2 border rounded text-secondaryText"
        />
      </div>
      <label htmlFor="paragraph" className="block px-3 text-sm font-bold mb-2">
        Paragraph: Links should be like this in the text: [Linktext](/link) They
        should lead to another article!
      </label>
      {article.content.map((para, paraIndex) => (
        <div key={paraIndex} className="w-full px-3 mb-6">
          <textarea
            placeholder="Paragraph text... This is an example text with a [Link](/link). The link will be blue in the end. "
            value={para.text}
            onChange={(e) => handleTextChange(paraIndex, e)}
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
          {article.content.length > 1 && (
            <PrimaryButton onClick={() => handleDeleteParagraph(paraIndex)}>
              Delete Paragraph
            </PrimaryButton>
          )}
        </div>
      ))}
      <div className="w-full px-3">
        <PrimaryButton onClick={handleAddParagraph}>
          Add Paragraph
        </PrimaryButton>
      </div>
      <div className="w-full px-3 mt-4">
        <PrimaryButton onClick={() => handleSubmit()}>
          Submit Article
        </PrimaryButton>
      </div>
    </div>
  );
};

export default UploadArticle;
