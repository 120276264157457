import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";

export const callTwitchExchangeFunction = async (code: any) => {
  const exchangeTwitchToken = httpsCallable(functions, "exchangeTwitchToken");

  try {
    const result = await exchangeTwitchToken({ code });
    console.log("Result from function:", result);
    console.log("Data from function:", result.data); // Process the response
    return result.data;
  } catch (error) {
    console.error("Error calling Twitch exchange function:", error);
  }
};
