// Header.js
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/sponsearly.png"; // Adjust the path as necessary
import { useAuth } from "../../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth, realTimeDb } from "../../firebaseConfig";
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import NavDropdown from "./NavDropdown";
import { useEffect, useState } from "react";
import { off, onValue, ref } from "firebase/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const navigate = useNavigate();
  const { currentUser, userRole } = useAuth();
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to handle menu toggle

  useEffect(() => {
    if (currentUser) {
      const userChatsRef = ref(realTimeDb, `userChats/${currentUser.uid}`);

      onValue(userChatsRef, (snapshot) => {
        let unread = false;
        snapshot.forEach((childSnapshot) => {
          if (childSnapshot.val().unread) {
            unread = true;
          }
        });
        setHasUnreadMessages(unread);
      });

      return () => {
        off(userChatsRef);
      };
    }
  }, [currentUser]);

  useEffect(() => {
    console.log("Message received");
  }, [hasUnreadMessages]);

  let getStartedDropdownItems;
  if (userRole === "creator") {
    getStartedDropdownItems = [
      { title: "How It Works", route: "/how-it-works-creator" },
    ];
  } else if (userRole === "brand") {
    getStartedDropdownItems = [
      { title: "How It Works", route: "/how-it-works-brand" },
      { title: "Pricing for brands", route: "/pricing-for-brands" },
    ];
  } else {
    getStartedDropdownItems = [
      { title: "How It Works", route: "/how-it-works" },
      { title: "Pricing for brands", route: "/pricing-for-brands" },
      { title: "Pricing for creators", route: "/pricing-for-creators" },
    ];
  }
  // { title: "Early Sponsorship", route: "/early-sponsorship" },

  // const creatorsDropdownItems = [
  //   { title: "Success Stories", route: "/success-stories" },
  //   { title: "Brands", route: "/brands" },
  //   { title: "How It Works", route: "/how-it-works" },
  // ];

  // const brandsDropdownItems = [
  //   { title: "Early Sponsorship", route: "/early-sponsorship" },
  //   { title: "Fresh Talents", route: "/fresh-talents" },
  //   { title: "Pricing", route: "/pricing" },
  // ];

  const resourceDropdownItems = [
    // { title: "Success stories", route: "/success-stories" },
    { title: "Blog", route: "/blog" },
    { title: "Support", route: "/support" },
  ];
  const aboutUsDropdownItems = [
    { title: "That´s us", route: "/we" },
    // { title: "Data Protection", route: "/data-protection" },
  ];

  const handleLoginClick = () => {
    navigate("/login"); // Redirect to the Login page
    setIsMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      setIsMenuOpen(false);
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="sticky top-0 z-50 md:flex md:justify-between md:items-center p-4 bg-backgroundSecondary !bg-opacity-80 shadow-sticky backdrop-blur-sm transition">
      <div className="flex justify-between items-center">
        <Link to="/" onClick={closeMenu}>
          {/* Sponsearly */}
          <img
            src={logo}
            alt="Sinify Logo"
            className="h-12 w-12 rounded-full"
          />
        </Link>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="md:hidden"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      <nav className="hidden md:flex">
        <div className="flex items-center">
          {userRole === "brand" && (
            <Link onClick={closeMenu} to="/fresh-talents" className="p-2">
              Creators
            </Link>
          )}
          {userRole === "creator" && (
            <Link onClick={closeMenu} to="/brands" className="p-2">
              Brands
            </Link>
          )}
          <NavDropdown
            title="Get Started"
            items={getStartedDropdownItems}
            closeMenu={() => setIsMenuOpen(false)}
          />
          {/* <NavDropdown
            title="Brands"
            items={brandsDropdownItems}
            closeMenu={() => setIsMenuOpen(false)}
          /> */}
          <NavDropdown
            title="Resource"
            items={resourceDropdownItems}
            closeMenu={() => setIsMenuOpen(false)}
          />
          <NavDropdown
            title="About us"
            items={aboutUsDropdownItems}
            closeMenu={() => setIsMenuOpen(false)}
          />
          {currentUser && (
            <Link onClick={closeMenu} to="/chats" className="relative p-2">
              Chats
              {hasUnreadMessages && (
                <span className="absolute top-0 right-0 h-2 w-2 bg-red-500 rounded-full"></span> // Notification indicator
              )}
            </Link>
          )}
          {currentUser && (
            <Link onClick={closeMenu} to="/profile" className="p-2">
              {currentUser.displayName}
              <div className="text-xs text">
                {userRole === "creator" ? "Creator" : "Brand"} Profile
              </div>
            </Link>
          )}
          {currentUser ? (
            <SecondaryButton onClick={handleLogout}>Log Out</SecondaryButton>
          ) : (
            <PrimaryButton onClick={handleLoginClick}>
              Log In / Sign Up
            </PrimaryButton>
          )}{" "}
        </div>
      </nav>

      <nav
        className={`absolute md:hidden z-10 bg-backgroundSecondary w-full transition-transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div
          className={`flex flex-col md:flex-row md:items-center ${
            isMenuOpen ? "block" : "hidden"
          } md:block`}
        >
          {/* <NavDropdown
            title="Creators"
            items={creatorsDropdownItems}
            closeMenu={() => setIsMenuOpen(false)}
          />
          <NavDropdown
            title="Brands"
            items={brandsDropdownItems}
            closeMenu={() => setIsMenuOpen(false)}
          /> */}
          {userRole === "brand" && (
            <Link onClick={closeMenu} to="/fresh-talents" className="p-2">
              Creators
            </Link>
          )}
          {userRole === "creator" && (
            <Link onClick={closeMenu} to="/brands" className="p-2">
              Brands
            </Link>
          )}
          <NavDropdown
            title="Get Started"
            items={getStartedDropdownItems}
            closeMenu={() => setIsMenuOpen(false)}
          />
          <NavDropdown
            title="Resource"
            items={resourceDropdownItems}
            closeMenu={() => setIsMenuOpen(false)}
          />
          <NavDropdown
            title="About us"
            items={aboutUsDropdownItems}
            closeMenu={() => setIsMenuOpen(false)}
          />
          {currentUser && (
            <Link onClick={closeMenu} to="/chats" className="relative p-2">
              Chats
              {hasUnreadMessages && (
                <span className="absolute top-0 right-0 h-2 w-2 bg-red-500 rounded-full"></span> // Notification indicator
              )}
            </Link>
          )}
          {currentUser && (
            <Link onClick={closeMenu} to="/profile" className="p-2">
              Profile
              <div className="text-xs text">
                {currentUser.displayName} {userRole}
              </div>
            </Link>
          )}
          {currentUser ? (
            <SecondaryButton onClick={handleLogout}>Log Out</SecondaryButton>
          ) : (
            <PrimaryButton onClick={handleLoginClick}>
              Log In / Sign Up
            </PrimaryButton>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
