// src/components/CreatorTable.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../../../interfaces/IUser";
import { truncateItems } from "../utils/utils";
import ProfilePicture from "../../../../components/ProfilePicture/ProfilePicture";
import { FilterValue, RangeFilter, SortType } from "../CreatorSearch";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/shadcn/Table";
import { Input } from "src/components/shadcn/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

type ColumnType = {
  Header: string;
  accessor: keyof IUser;
  width?: string; // Add optional width property
};

interface CreatorTableProps {
  creators: IUser[];
  onSort: (field: keyof IUser) => void;
  onFilterChange: (
    field: keyof IUser,
    value: string,
    rangePart?: "min" | "max"
  ) => void;
  filters: { [key in keyof IUser]?: FilterValue }; // Use the new FilterValue type here

  // filters: { [key in keyof IUser]?: string };
  isCreatorSearch: boolean;
  sort: SortType;
}

const CreatorTable: React.FC<CreatorTableProps> = ({
  creators,
  onSort,
  onFilterChange,
  filters,
  isCreatorSearch,
  sort,
}) => {
  let columns: ColumnType[] = [
    { Header: "Username", accessor: "displayName", width: "15%" },
    // { Header: "Email", accessor: "email" },
    // { Header: "Role", accessor: "role" },
    { Header: "Audience", accessor: "audience", width: "15%" },
    { Header: "Categories", accessor: "favoriteCategories", width: "30%" },
    { Header: "Products", accessor: "favoriteProducts", width: "30%" },
  ];

  if (isCreatorSearch) {
    columns = columns.concat([
      { Header: "Followers", accessor: "followers_total", width: "8%" },
      {
        Header: "Followers gained in week",
        accessor: "followers_change",
        width: "10%",
      },
    ]);
  } else {
    columns = columns.map((col) => {
      if (col.Header === "Username") {
        col.Header = "Brand";
        col.width = "20%";
      }
      if (col.Header === "Categories") {
        col.Header = "Open Categories";
        col.width = "20%";
      }
      if (col.Header === "Products") {
        col.Header = "Open Products";
        col.width = "20%";
      }
      return col;
    });
  }

  const navigate = useNavigate();

  const handleRowClick = (creatorId?: string) => {
    navigate(`/creator-profile/${creatorId}`);
  };

  return (
    <div className="overflow-x-auto mt-6">
      <Table>
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHead
                key={column.accessor}
                className="cursor-pointer"
                // className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => onSort(column.accessor)}
                style={{ width: column.width }}
              >
                {column.Header}{" "}
                {sort.field === column.accessor ? (
                  sort.direction === "asc" ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )
                ) : (
                  <FontAwesomeIcon icon={faSort} />
                )}
              </TableHead>
            ))}
          </TableRow>
          <TableRow>
            {columns.map((column) => (
              <TableHead key={column.accessor}>
                {column.accessor === "followers_change" ||
                column.accessor === "followers_total" ? (
                  <div className="flex flex-row gap-1">
                    <Input
                      type="text"
                      value={
                        typeof filters[column.accessor] === "object" &&
                        filters[column.accessor] !== null
                          ? (filters[column.accessor] as RangeFilter).min || ""
                          : ""
                      }
                      onChange={(e) =>
                        onFilterChange(column.accessor, e.target.value, "min")
                      }
                      className="w-1/2 px-2 py-1 border rounded text-secondaryText bg-white" // Adjusted width to w-1/4
                      placeholder="Min"
                    />
                    <Input
                      type="text"
                      value={
                        typeof filters[column.accessor] === "object" &&
                        filters[column.accessor] !== null
                          ? (filters[column.accessor] as RangeFilter).max || ""
                          : ""
                      }
                      onChange={(e) =>
                        onFilterChange(column.accessor, e.target.value, "max")
                      }
                      className="w-1/2 px-2 py-1 border rounded text-secondaryText bg-white" // Adjusted width to w-1/4
                      placeholder="Max"
                    />
                  </div>
                ) : (
                  <Input
                    type="text"
                    value={filters[column.accessor] as string}
                    onChange={(e) =>
                      onFilterChange(column.accessor, e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded text-secondaryText bg-white"
                    placeholder={`Filter by ${column.Header}`}
                  />
                )}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {creators.map((creator) => (
            <TableRow
              key={creator.id}
              onClick={() => handleRowClick(creator?.id)}
              className="cursor-pointer hover:bg-backgroundSecondary transition-colors duration-150" // Add hover effect here
            >
              {columns.map((column) => (
                <TableCell
                  key={column.accessor}
                  style={{ width: column.width }}
                  className={`truncate overflow-hidden text-ellipsis ${
                    column.accessor === "displayName" && "py-0"
                  }`}
                  // className={`${
                  //   column.accessor === "followers_change" ||
                  //   column.accessor === "followers_total"
                  //     ? "w-1/12" // Apply custom class
                  //     : ""
                  // } ${column.accessor === "displayName" && "py-0"}`}
                >
                  {column.accessor === "displayName" ? (
                    <div className="flex flex-row items-center justify-start py-0">
                      <ProfilePicture userId={creator?.id ?? ""} size="2" />{" "}
                      {/* Adjust size as needed */}
                      <div className="ml-4 flex-grow justify-center items-center align-middle">
                        {creator[column.accessor]}
                      </div>
                    </div>
                  ) : column.accessor === "followers_change" &&
                    creator?.followers_change &&
                    creator.followers_gained ? (
                    <>
                      <div className="flex justify-start">
                        <span
                          className={`flex-1 mr-2 ${
                            creator.followers_gained >= 0
                              ? "text-primary"
                              : "text-accent"
                          }`}
                        >
                          {creator.followers_gained}
                        </span>
                        <span
                          className={`flex-1 ${
                            creator?.followers_change >= 0
                              ? "text-primary"
                              : "text-accent"
                          }`}
                        >
                          {creator?.followers_change &&
                            creator.followers_change?.toFixed(2)}
                          %
                        </span>
                      </div>
                    </>
                  ) : Array.isArray(creator[column.accessor]) ? (
                    truncateItems(
                      creator[column.accessor],
                      filters[column.accessor]
                    )
                  ) : (
                    creator[column.accessor]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default CreatorTable;
