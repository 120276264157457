// src/components/UserProfile.js
import { useState, useEffect, Fragment, useRef } from "react";
import { db, auth, storage } from "../../firebaseConfig";
import {
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import {
  updateProfile,
  sendEmailVerification,
  verifyBeforeUpdateEmail,
  signOut,
  EmailAuthProvider,
  updatePassword,
  reauthenticateWithCredential,
} from "firebase/auth";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { groupedProductOptions } from "../../utils/selectOptions/productOptions";
import { categoryOptions } from "../../utils/selectOptions/categoryOptions";
import { IUser } from "../../interfaces/IUser";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import DangerButton from "../../components/Buttons/DangerButton";
import { useAuth } from "../../context/AuthContext";
import { TOASTMESSAGES, useToast } from "../../context/ToastContext";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import ProfileBannerDisplay from "../../components/ProfileBannerDisplay/ProfileBannerDisplay";
import { audienceOptions } from "../../utils/selectOptions/regionOptions";
import { Label } from "src/components/shadcn/Label";
import { Input } from "src/components/shadcn/Input";
import { Textarea } from "src/components/shadcn/Textarea";

const UserProfile = () => {
  const [user, setUser] = useState<IUser>({
    displayName: "",
    email: "",
    address: {
      firstName: "",
      surname: "",
      street: "",
      postalCode: "",
      city: "",
      country: "",
      state: "",
    },
    about: "",
    role: "",
    companyName: "",
    contactChannel: "",
    typeOfContent: "",
    audience: [],
    twitchName: "",
    website: "",
    favoriteProducts: [],
    favoriteCategories: [],
    productLinks: [],
    // Add other user fields as needed
  });
  const [loading, setLoading] = useState(false);
  const [isReallyDeleteTwitchData, setIsReallyDeleteTwitchData] =
    useState(false);
  const [isReallyDeleteAccount, setIsReallyDeleteAccount] = useState(false);
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const profileInputRef = useRef<HTMLInputElement>(null);
  const backgroundInputRef = useRef<HTMLInputElement>(null);

  const [userIdForPic, setUserIdForPic] = useState("");
  const [linkInput, setLinkInput] = useState("");

  const navigate = useNavigate();
  const showToast = useToast();

  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        setUserIdForPic(auth.currentUser.uid);
        const userRef = doc(db, "users", auth.currentUser.uid);
        if (!auth.currentUser.emailVerified) {
          showToast(TOASTMESSAGES.CONFIRM_EMAIL, true);
        }

        let docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          const userData = docSnap.data() as IUser;
          // Ensure all fields are initialized
          setUser({
            displayName: userData.displayName || "",
            email: userData.email || "",
            address: {
              firstName: userData.address?.firstName || "",
              surname: userData.address?.surname || "",
              street: userData.address?.street || "",
              postalCode: userData.address?.postalCode || "",
              city: userData.address?.city || "",
              country: userData.address?.country || "",
              state: userData.address?.state || "",
            },
            role: userData.role,
            about: userData.about || "",
            companyName: userData.companyName || "",
            contactChannel: userData.contactChannel || "",
            typeOfContent: userData.typeOfContent || "",
            audience: userData.audience || [],
            website: userData.website || "",
            twitchName: userData.twitchName || "",
            favoriteProducts: userData.favoriteProducts || [],
            favoriteCategories: userData.favoriteCategories || [],
            productLinks: userData.productLinks || [],
            hasValidDisplayName: true,
            isTwitchUserAuthenticated:
              userData.isTwitchUserAuthenticated || false,
          });

          console.log("currentuser", currentUser);
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      if (auth.currentUser?.uid) {
        if (auth.currentUser && user.displayName.length > 0) {
          await updateProfile(auth.currentUser, {
            displayName: user.displayName,
            // ... other auth fields if needed
          });
          setUser({ ...user, hasValidDisplayName: true });
        }

        const userRef = doc(db, "users", auth?.currentUser?.uid);
        await updateDoc(userRef, {
          ...user,
        });
        showToast(TOASTMESSAGES.SUCCESS, true);

        console.log("User updated successfully");
      }
    } catch (error) {
      showToast(TOASTMESSAGES.FAIL, true);

      console.error("Error updating user:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = async () => {
    setLoading(true);

    try {
      if (auth.currentUser?.uid) {
        // Check if the current email is verified
        if (!auth.currentUser.emailVerified) {
          // Send verification email
          await sendEmailVerification(auth.currentUser);
          // Notify user to verify their email first
          alert("Please verify your current email before changing it.");
        } else if (user.email !== auth.currentUser.email) {
          // Update email if it's different and the current email is verified
          await verifyBeforeUpdateEmail(auth.currentUser, user.email);
          const userRef = doc(db, "users", auth?.currentUser?.uid);
          await updateDoc(userRef, {
            ...user,
          });
          await signOut(auth);
        }
      }
    } catch (error) {
      console.error("Error updating email:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    try {
      if (currentUser?.email) {
        // Re-authenticate user
        const credential = EmailAuthProvider.credential(
          currentUser.email,
          currentPassword
        );
        await reauthenticateWithCredential(currentUser, credential);

        // Update password
        await updatePassword(currentUser, newPassword);
      }
      alert("Password updated successfully");
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const handleDeleteAccount = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone."
    );

    if (confirm && auth.currentUser) {
      try {
        // Delete user data from Firestore
        const userRef = doc(db, "users", auth.currentUser.uid);
        await deleteDoc(userRef);

        // Delete user account
        await auth.currentUser.delete();
        alert("Account and data deleted successfully");
        navigate("/"); // Redirect to login or home page
      } catch (error: any) {
        console.error("Error deleting account and data:", error.message);
      }
    }
  };

  const handleDeleteTwitchTracker = async () => {
    setLoading(true);
    try {
      if (auth.currentUser?.uid) {
        const userRef = doc(db, "users", auth?.currentUser?.uid);
        await updateDoc(userRef, {
          ...user,
          twitchTracker: deleteField(), // Remove field if null
        });
        console.log("User deleted twitchData");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTwitchAuth = () => {
    const TWITCH_CLIENT_ID = process.env.REACT_APP_TWITCH_CLIENT_ID;
    const REDIRECT_URI = `${window.location.origin}/twitch-auth`;

    const twitchAuthUrl = `https://id.twitch.tv/oauth2/authorize?client_id=${TWITCH_CLIENT_ID}&redirect_uri=${encodeURIComponent(
      REDIRECT_URI
    )}&response_type=code&scope=user:read:email`;
    window.location.href = twitchAuthUrl;
  };

  const resendVerificationEmail = async () => {
    const user = auth.currentUser;

    if (user && !user.emailVerified) {
      try {
        await sendEmailVerification(user);
        showToast(TOASTMESSAGES.CONFIRM_EMAIL_SEND, true);
        // Optionally, show a message to the user or handle UI updates
      } catch (error) {
        console.error("Error sending verification email:", error);
        // Handle errors here, such as displaying a notification to the user
      }
    } else {
      console.log("No user logged in or email already verified.");
      // Handle the case where there is no user logged in or the email is already verified
    }
  };

  const uploadPicture = async (imageFile: any, type: string) => {
    if (!currentUser) return;
    let storageRef;
    if (type === "profile") {
      storageRef = ref(storage, `profilePictures/${currentUser.uid}`);
    } else {
      storageRef = ref(storage, `backgroundPictures/${currentUser.uid}`);
    }
    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Update user's profile in Firestore with the new image URL
      const userRef = doc(db, "users", currentUser.uid);
      setUserIdForPic("");
      if (type === "profile") {
        await updateDoc(userRef, { profilePicture: downloadURL });
      } else {
        await updateDoc(userRef, { backgroundPicture: downloadURL });
      }

      setUserIdForPic(currentUser.uid);
      showToast(TOASTMESSAGES.SUCCESS, true);
      console.log("Profile picture updated successfully");
    } catch (error) {
      console.error("Upload failed", error);
      showToast(TOASTMESSAGES.FAIL, true);
    }
  };

  const handleImageSelect = (event: any, type: string) => {
    const file = event.target.files[0]; // Get the selected file
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (file) {
      if (file.size > maxSize) {
        // If the file is larger than 5MB, show an error message
        showToast("File size should not exceed 5MB", true);
        return; // Stop the function from proceeding
      }
      uploadPicture(file, type); // Proceed with the upload if the size is within the limit
    }
  };

  // Function to add a new link
  const handleAddLink = () => {
    if (linkInput) {
      setUser({
        ...user,
        productLinks: [...(user.productLinks ?? []), linkInput],
      });
      setLinkInput(""); // Clear the input field
    }
  };

  // Function to remove a link
  const handleRemoveLink = (index: number) => {
    const updatedLinks = user?.productLinks?.filter((_, idx) => idx !== index);
    setUser({
      ...user,
      productLinks: updatedLinks,
    });
  };

  return (
    <div>
      {loading && <LoadingSpinner />}

      <div className="container mx-auto p-4">
        <ProfileBannerDisplay
          userId={userIdForPic}
          onProfileImageSelect={(event: any) =>
            handleImageSelect(event, "profile")
          }
          onBackgroundImageSelect={(event: any) =>
            handleImageSelect(event, "background")
          }
          profileInputRef={profileInputRef}
          backgroundInputRef={backgroundInputRef}
          isEditView={true}
        />
      </div>

      <div className="container mx-auto p-4">
        <h2 className="mb-4 flex flex-row justify-between">
          <div className="text-xl font-bold ">
            Update your {user.role === "creator" ? "User" : "Brand"} Profile
          </div>
          {auth.currentUser && (
            <PrimaryButton
              onClick={() =>
                navigate(`/creator-profile/${auth?.currentUser?.uid}`)
              }
            >
              Check my Profile
            </PrimaryButton>
          )}
        </h2>

        {!auth.currentUser?.emailVerified && (
          <div className="mb-4">
            <PrimaryButton onClick={() => resendVerificationEmail()}>
              Resend Verification Email
            </PrimaryButton>
          </div>
        )}
        {user.role === "creator" && (
          <Fragment>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full md:flex md:items-end">
                <div className="md:w-1/2 px-3 md:mb-0">
                  <Label
                    htmlFor="twitchName"
                    // className="block text-sm font-bold mb-2"
                  >
                    Twitch Name:{" "}
                  </Label>
                  <Input
                    type="text"
                    id="twitchName"
                    placeholder="Twitch Name"
                    value={user.twitchName}
                    disabled={user.isTwitchUserAuthenticated}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        twitchName: e.target.value,
                        isTwitchUserAuthenticated: false, // Set to false when twitchName changes
                      })
                    }
                    className={`w-full  mb-2 md:mb-0 
                    ${
                      user.isTwitchUserAuthenticated
                        ? "bg-gray-200 text-gray-500 border-gray-300 cursor-not-allowed"
                        : "bg-white text-black border-gray-400"
                    }`}
                  />
                </div>
                <div className="md:w-1/2 px-3 md:mb-0">
                  {!user.isTwitchUserAuthenticated ? (
                    <div>
                      <p className="text-sm my-2">
                        You changed your twitch Name or did not set it all. We
                        recommend to validate your channel ownership by
                        authenticating your twitch account.
                      </p>
                      <div className="">
                        {/* <div className="md:w-1/2 px-3 md:mb-0"> */}
                        <PrimaryButton onClick={() => handleTwitchAuth()}>
                          Authenticate your Twitch Account
                        </PrimaryButton>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <p className="text-sm my-2">
                        You can remove the connection to your twitch account.
                        Please consider, that we also delete all the data
                        connected with that account. The operation is
                        irreversible.
                      </p>
                      {/* <div className="md:w-1/2 px-3 md:mb-0"> */}
                      {!isReallyDeleteTwitchData ? (
                        <SecondaryButton
                          onClick={() => setIsReallyDeleteTwitchData(true)}
                        >
                          Remove Twitch Account
                        </SecondaryButton>
                      ) : (
                        <DangerButton
                          onClick={() => {
                            setUser({
                              ...user,
                              twitchName: "",
                              isTwitchUserAuthenticated: false,
                            });
                            handleDeleteTwitchTracker();
                          }}
                        >
                          Are you sure?
                        </DangerButton>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr className="my-4 border-t-2 border-primary w-full rounded-lg shadow-lg" />
          </Fragment>
        )}
        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label
              htmlFor="displayName"
              // className="block text-sm font-bold mb-2"
            >
              {user.role === "creator" ? "User" : "Brand"} Name:
            </Label>
            <Input
              type="text"
              id="displayName"
              placeholder={`${user.role === "creator" ? "User" : "Brand"} Name`}
              value={user.displayName}
              onChange={(e) =>
                setUser({ ...user, displayName: e.target.value })
              }
              className="w-full bg-white"
            />
          </div>
          {user.role === "brand" && (
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label
                htmlFor="website"
                // className="block text-sm font-bold mb-2"
              >
                Website:
              </Label>
              <Input
                type="text"
                id="website"
                placeholder="Website"
                value={user.website}
                onChange={(e) => setUser({ ...user, website: e.target.value })}
                className="w-full  bg-white"
              />
            </div>
          )}
        </div>

        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full px-3">
            <Label
              htmlFor="about"
              // className="block text-sm font-bold mb-2"
            >
              About:
            </Label>
            <Textarea
              id="about"
              placeholder={
                user.role === "creator"
                  ? "Tell use more about you and what you do, so the sponsors will get to know you. The better the sponsors get to know you, the higher the chances of finding a sponsor!"
                  : "Tell us more about your brand. The better the creators will get to know you, the higher the chances are to find the best creators!"
              }
              value={user.about}
              onChange={(e) => setUser({ ...user, about: e.target.value })}
              className="w-full h-32 bg-white"
            ></Textarea>
          </div>
        </div>

        <hr className="my-4 border-t-2 border-primary w-full rounded-lg shadow-lg" />
        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label
              htmlFor="firstName"
              // className="block text-sm font-bold mb-2"
            >
              First Name:
            </Label>
            <Input
              type="text"
              id="firstName"
              placeholder="First Name"
              value={user.address.firstName}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: { ...user.address, firstName: e.target.value },
                })
              }
              className="w-full  bg-white"
            />
          </div>

          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label
              htmlFor="surname"
              // className="block text-sm font-bold mb-2"
            >
              Surname:
            </Label>
            <Input
              type="text"
              id="surname"
              placeholder="Surname"
              value={user.address.surname}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: { ...user.address, surname: e.target.value },
                })
              }
              className="w-full  bg-white"
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-4">
          {user.role === "brand" && (
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label
                htmlFor="state"
                // className="block text-sm font-bold mb-2"
              >
                Company Name:
              </Label>
              <Input
                type="text"
                id="companyName"
                placeholder="Company Name"
                value={user.companyName}
                onChange={(e) =>
                  setUser({ ...user, companyName: e.target.value })
                }
                className="w-full  bg-white"
              />
            </div>
          )}
          <div
            // className="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            className={`w-full ${
              user.role === "brand" && "md:w-1/2"
            } px-3 mb-6 md:mb-0`}
          >
            <Label
              htmlFor="street"
              // className="block text-sm font-bold mb-2"
            >
              Street & Housenumber:
            </Label>
            <Input
              type="text"
              id="street"
              placeholder="Street"
              value={user.address.street}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: { ...user.address, street: e.target.value },
                })
              }
              className="w-full  bg-white"
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label
              htmlFor="postalCode"
              // className="block text-sm font-bold mb-2"
            >
              Postalcode:
            </Label>
            <Input
              type="text"
              id="postalCode"
              placeholder="Postalcode"
              value={user.address.postalCode}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: { ...user.address, postalCode: e.target.value },
                })
              }
              className="w-full  bg-white"
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label
              htmlFor="city"
              // className="block text-sm font-bold mb-2"
            >
              City:
            </Label>
            <Input
              type="text"
              id="city"
              placeholder="City"
              value={user.address.city}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: { ...user.address, city: e.target.value },
                })
              }
              className="w-full  bg-white"
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label
              htmlFor="country"
              // className="block text-sm font-bold mb-2"
            >
              Country:
            </Label>
            <Input
              type="text"
              id="country"
              placeholder="Country"
              value={user.address.country}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: { ...user.address, country: e.target.value },
                })
              }
              className="w-full  bg-white"
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label
              htmlFor="state"
              // className="block text-sm font-bold mb-2"
            >
              State:
            </Label>
            <Input
              type="text"
              id="state"
              placeholder="State"
              value={user.address.state}
              onChange={(e) =>
                setUser({
                  ...user,
                  address: { ...user.address, state: e.target.value },
                })
              }
              className="w-full  bg-white"
            />
          </div>
        </div>

        <hr className="my-4 border-t-2 border-primary w-full rounded-lg shadow-lg" />

        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            {" "}
            <Label
              htmlFor="contactChannel"
              // className="block text-sm font-bold mb-2"
            >
              Contact Channel:
            </Label>
            <Input
              type="text"
              id="contactChannel"
              placeholder="Email, Phone, WhatsApp, WeChat, etc."
              value={user.contactChannel}
              onChange={(e) =>
                setUser({ ...user, contactChannel: e.target.value })
              }
              className="w-full  bg-white"
            />
          </div>

          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            {" "}
            <Label
              htmlFor="audience"
              // className="block text-sm font-bold mb-2"
            >
              Audience:
            </Label>
            {/* <input
              type="text"
              id="audience"
              placeholder="Audience"
              value={user.audience}
              onChange={(e) => setUser({ ...user, audience: e.target.value })}
              className="w-full px-3 py-2 border rounded text-secondaryText"
            /> */}
            <MultiSelect
              elements={audienceOptions}
              selectedElements={user.audience}
              setSelectedElements={(elements) =>
                setUser({ ...user, audience: elements })
              }
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label
              htmlFor="product-select"
              // className="block text-sm font-bold mb-2"
            >
              {user.role === "creator" && "Open "}Categories:
            </Label>
            <MultiSelect
              elements={categoryOptions}
              selectedElements={user.favoriteCategories}
              setSelectedElements={(elements) =>
                setUser({ ...user, favoriteCategories: elements })
              }
            />
          </div>

          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Label
              htmlFor="product-select"
              // className="block text-sm font-bold mb-2"
            >
              {user.role === "creator" && "Open "}Products:
            </Label>
            <MultiSelect
              elements={groupedProductOptions}
              selectedElements={user.favoriteProducts}
              setSelectedElements={(elements) =>
                setUser({ ...user, favoriteProducts: elements })
              }
            />
          </div>

          <div className=" text-secondaryText px-3 pb-3 w-full flex flex-col items-center justify-center">
            <p className="text-sm w-full my-2">
              Choose the categories and products that will be the subject of
              your sponsorship or that most include your interests. Selection
              helps to find suitable partners for cooperation.
            </p>
            {user.role === "brand" && (
              <p className="text-sm w-full my-2">
                If your product is not in the selection, don't be afraid to
                contact our{" "}
                <a
                  href="/support"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-accent underline hover:no-underline"
                >
                  support
                </a>
                .
              </p>
            )}
          </div>
        </div>

        {user.role === "brand" && (
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <div className="w-full flex gap-4">
                {/* Input for adding a new link */}
                <div className="w-full">
                  <Label
                    htmlFor="product-select"
                    // className="block text-sm font-bold mb-2"
                  >
                    Product Links:
                  </Label>
                  <div className="flex gap-4">
                    <Input
                      type="text"
                      value={linkInput}
                      onChange={(e) => setLinkInput(e.target.value)}
                      placeholder="Add product link"
                      className="w-full  bg-white"
                    />
                    <PrimaryButton onClick={handleAddLink}>Add</PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <div className="grid grid-cols-2 gap-4">
                {user?.productLinks?.map((link, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center"
                  >
                    <a
                      href={
                        /^(http:\/\/|https:\/\/)/.test(link)
                          ? link
                          : `https://${link}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary truncate" // Use 'truncate' to handle long URLs
                    >
                      {link}
                    </a>
                    <button
                      onClick={() => handleRemoveLink(index)}
                      className="text-xs bg-remove text-white p-1 rounded hover:bg-removeHover" // Adjust styles as needed
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <hr className="my-4 border-t-2 border-primary w-full rounded-lg shadow-lg" />

        <div className="flex justify-between">
          <PrimaryButton onClick={() => handleSubmit()}>Confirm</PrimaryButton>
          {user.role === "brand" && (
            <SecondaryButton onClick={() => navigate("/account")}>
              Manage Account
            </SecondaryButton>
          )}
        </div>

        <hr className="my-4 border-t-2 border-primary w-full rounded-lg shadow-lg" />

        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full md:flex md:items-end">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label
                htmlFor="email"
                // className="block text-sm font-bold mb-2"
              >
                Email:
              </Label>
              <Input
                type="email"
                id="email"
                placeholder="Email"
                value={isEmailTouched ? user.email : currentUser?.email || ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    email: e.target.value,
                  });
                  setIsEmailTouched(true);
                }}
                className="w-full  bg-white"
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <p className="text-secondaryText text-sm my-2 pb-3">
                Please consider, that if you change your email, you will
                immediately be logged out. You will have to confirm the new
                email!
              </p>
              <PrimaryButton onClick={() => handleEmailChange()}>
                Change Email
              </PrimaryButton>
            </div>
          </div>
        </div>
        <hr className="my-4 border-t-2 border-primary w-full rounded-lg shadow-lg" />
        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full md:flex md:items-end">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label
                htmlFor="email"
                // className="block text-sm font-bold mb-2"
              >
                Current Password:
              </Label>
              <Input
                type="password"
                id="currentPassword"
                placeholder="Current Password"
                value={currentPassword}
                onChange={(e) => {
                  setCurrentPassword(e.target.value);
                }}
                className="w-full  bg-white"
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <Label
                htmlFor="email"
                // className="block text-sm font-bold mb-2"
              >
                New Password:
              </Label>
              <Input
                type="password"
                id="newPassword"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                className="w-full  bg-white"
              />
              {/* <p className="text-sm my-2">
                Please consider, that if you change your email, you will
                immediately be logged out. You will have to confirm the new
                email!
              </p> */}
            </div>
            <div className="w-full md:flex md:items-end">
              <PrimaryButton onClick={() => handleChangePassword()}>
                Change Password
              </PrimaryButton>
            </div>
          </div>
        </div>
        <hr className="my-4 border-t-2 border-primary w-full rounded-lg shadow-lg" />

        <div>
          <p className="text-accent mb-2">Dangerzone!</p>
          {!isReallyDeleteAccount ? (
            <SecondaryButton onClick={() => setIsReallyDeleteAccount(true)}>
              Delete Account
            </SecondaryButton>
          ) : (
            <DangerButton onClick={() => handleDeleteAccount()}>
              Delete My Account!
            </DangerButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
