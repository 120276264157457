import { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../../firebaseConfig";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { categoryOptions } from "../../../utils/selectOptions/categoryOptions";
import { IUser } from "../../../interfaces/IUser";
import { groupedProductOptions } from "../../../utils/selectOptions/productOptions";
import { TOASTMESSAGES, useToast } from "../../../context/ToastContext";
import { useAuth } from "../../../context/AuthContext";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import { audienceOptions } from "../../../utils/selectOptions/regionOptions";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/shadcn/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const UserProfileCreation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayNameValid, setIsDisplayNameValid] = useState(true);

  const [isInviterValid, setIsInviterValid] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState<number | null>(null); // State to manage debounce timeout
  const [lastCheckedDisplayName, setLastCheckedDisplayName] =
    useState<string>(""); // New state

  const [user, setUser] = useState<IUser>({
    displayName: "",
    email: "",
    address: {
      firstName: "",
      surname: "",
      street: "",
      postalCode: "",
      city: "",
      country: "",
      state: "",
    },
    contactChannel: "",
    typeOfContent: "",
    audience: [],
    twitchName: "",
    favoriteProducts: [],
    favoriteCategories: [],
    productLinks: [],
    role: "creator",
    inviterName: "",
    hasValidDisplayName: true,
    // Add other user fields as needed
  });

  const showToast = useToast();
  const { updateUserRole } = useAuth(); // Destructure the new method

  const navigate = useNavigate();

  const checkDisplayNameExists = async (displayName: string) => {
    if (displayName.trim() === "") {
      setIsDisplayNameValid(false);
      return;
    }

    if (displayName === lastCheckedDisplayName) {
      // If the displayName is the same as the last one checked, don't query the database again
      return;
    }

    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("displayName", "==", displayName));
      const querySnapshot = await getDocs(q);

      setLastCheckedDisplayName(displayName); // Store the last checked displayName

      if (!querySnapshot.empty) {
        setIsDisplayNameValid(false); // Username is taken
      } else {
        setIsDisplayNameValid(true); // Username is available
      }
    } catch (error) {
      console.error("Error checking displayName:", error);
      setIsDisplayNameValid(false);
    }
  };
  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeoutId = window.setTimeout(() => {
      if (user.displayName) checkDisplayNameExists(user.displayName);
    }, 500); // Debounce time of 500ms

    setDebounceTimeout(timeoutId);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [user.displayName]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        try {
          const userRef = doc(db, "users", auth.currentUser.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const userData = docSnap.data() as IUser;
            setUser({
              ...userData,
              displayName: userData?.displayName || "",
              email: userData?.email || "",
              address: {
                firstName: userData.address?.firstName || "",
                surname: userData.address?.surname || "",
                street: userData.address?.street || "",
                postalCode: userData.address?.postalCode || "",
                city: userData.address?.city || "",
                country: userData.address?.country || "",
                state: userData.address?.state || "",
              },
              contactChannel: userData?.contactChannel || "",
              typeOfContent: userData?.typeOfContent || "",
              audience: userData?.audience || [],
              twitchName: userData?.twitchName || "",
              favoriteProducts: userData?.favoriteProducts || [],
              favoriteCategories: userData?.favoriteCategories || [],
              hasValidDisplayName: userData?.hasValidDisplayName || true,
              role: userData?.role || "creator",
            });
          } else {
            console.log("User document does not exist.");
            // Handle the case where the user document doesn't exist if needed
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [auth.currentUser]);

  const checkInviterExists = async (inviterName: string) => {
    if (inviterName.trim() === "") {
      setIsInviterValid(true);
      return true;
    }

    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("displayName", "==", inviterName));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setIsInviterValid(true);
        return true;
      } else {
        setIsInviterValid(false);

        return false;
      }
    } catch (error) {
      console.error("Error checking inviter:", error);
      setIsInviterValid(false);
      return false;
    }
  };

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeoutId = window.setTimeout(async () => {
      if (user.inviterName) await checkInviterExists(user.inviterName);
    }, 1000);

    setDebounceTimeout(timeoutId);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [user.inviterName]);

  const updateInviterData = async (
    inviterName: string,
    invitedUserName: string
  ) => {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("displayName", "==", inviterName));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const inviterDocRef = querySnapshot.docs[0].ref;
        const inviterDocData = querySnapshot.docs[0].data();

        const invitedUsers = inviterDocData.invitedUsers || [];

        const updatedInvitedUsers = [
          ...invitedUsers,
          {
            displayName: invitedUserName,
            isTwitchAuthenticated: false,
          },
        ];
        console.log("Before updateing inviter");

        await updateDoc(inviterDocRef, { invitedUsers: updatedInvitedUsers });

        return true;
      }
    } catch (error) {
      console.error("Error updating inviter data:", error);
      return false;
    }
  };

  const updateUserData = async () => {
    if (auth.currentUser) {
      try {
        setIsLoading(true);
        const userProfile = user as { [x: string]: any }; // Type assertion

        // Update displayName in Firebase Authentication
        if (user.displayName && user.displayName.length > 0) {
          await updateProfile(auth.currentUser, {
            displayName: user.displayName,
          });
          setUser({ ...user });
        }

        // Update user profile in Firestore
        const userRef = doc(db, "users", auth.currentUser.uid);

        await updateDoc(userRef, userProfile);

        // Update inviter's data if inviterName is provided
        if (user.inviterName) {
          const success = await updateInviterData(
            user.inviterName,
            user.displayName
          );
          if (!success) {
            throw new Error("Failed to update inviter data");
          }
        }

        // alert("Profile Updated Successfully!");
        showToast(TOASTMESSAGES.SUCCESS, true);
        if (userProfile.role) {
          updateUserRole(userProfile.role);
        }
        setIsLoading(false);
        navigate("/brands");
      } catch (error) {
        console.error("Error updating user profile:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isFormValid =
    isDisplayNameValid &&
    user.displayName &&
    user.contactChannel &&
    user.audience &&
    user.favoriteCategories.length > 0 &&
    user.favoriteProducts.length > 0 &&
    (isInviterValid || user?.inviterName === "" || !user.inviterName);

  return (
    <div className="container mx-auto p-4">
      {isLoading && <LoadingSpinner />}
      <h2 className="text-xl font-bold mb-4">Create Your Profile</h2>
      <p className="mb-4">
        We need some crucial information so you can find sponsors. Please take
        your time to create your account.
      </p>
      Please pick a username. This will be displayed to others in the
      application!
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label htmlFor="displayName" className="block text-sm font-bold mb-2">
            Username: *
          </label>
          <input
            type="text"
            id="displayName"
            placeholder="Username"
            value={user.displayName}
            onChange={(e) => setUser({ ...user, displayName: e.target.value })}
            className={`w-full px-3 py-2 border rounded ${
              isDisplayNameValid ? "text-secondaryText" : "border-red-500"
            }`}
          />
          {!isDisplayNameValid && (
            <p className="text-red-600 text-sm mt-2">
              Username is already taken.
            </p>
          )}
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label htmlFor="twitchName" className="block text-sm font-bold mb-2">
            Twitchname: *
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className=" text-primary ml-4"
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <p>The twitch name is used identify your twitch channel.</p>
                  <p>
                    Later in your profile you can also verify your account, so
                    brands know it´s you.
                  </p>
                  <p>
                    Without a twitch name you will not be shown to the brands.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </label>
          <input
            type="text"
            id="twitchName"
            placeholder="Twitchname"
            value={user.twitchName}
            onChange={(e) => setUser({ ...user, twitchName: e.target.value })}
            className={`w-full px-3 py-2 border rounded text-secondaryText`}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          Please let us know how we should contact you.
          <label
            htmlFor="contactChannel"
            className="block text-sm font-bold mb-2"
          >
            Contact Channel: *
          </label>
          <input
            type="text"
            id="contactChannel"
            placeholder="Enter your email, phone, WhatsApp, etc."
            value={user.contactChannel}
            onChange={(e) =>
              setUser({ ...user, contactChannel: e.target.value })
            }
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>

        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          Please specify in which regions you are active.{" "}
          <label htmlFor="audience" className="block text-sm font-bold mb-2">
            Audience: *
          </label>
          <MultiSelect
            elements={audienceOptions}
            selectedElements={user.audience}
            setSelectedElements={(elements) =>
              setUser({ ...user, audience: elements })
            }
          />
        </div>
      </div>
      Specify 1 - 5 favorite product categories and 1 - 5 favorite products you
      are interested in, so others can find you easily.
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            htmlFor="product-select"
            className="block text-sm font-bold mb-2"
          >
            Open Categories: *
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className=" text-primary ml-4"
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <p>What you pick here will be seen by brands later on.</p>
                  <p>Please pick only things you are willing to promote.</p>
                  <p>The brands can filter and look for your interests.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </label>
          <MultiSelect
            elements={categoryOptions}
            selectedElements={user.favoriteCategories}
            setSelectedElements={(elements) =>
              setUser({ ...user, favoriteCategories: elements })
            }
          />
        </div>

        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            htmlFor="product-select"
            className="block text-sm font-bold mb-2"
          >
            Open Products: *
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className=" text-primary ml-4"
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <p>What you pick here will be seen by brands later on.</p>
                  <p>Please pick only things you are willing to promote.</p>
                  <p>The brands can filter and look for your interests.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </label>
          <MultiSelect
            elements={groupedProductOptions}
            selectedElements={user.favoriteProducts}
            setSelectedElements={(elements) =>
              setUser({ ...user, favoriteProducts: elements })
            }
          />
        </div>
        <hr className="my-4 border-t-2 border-primary w-full rounded-lg shadow-lg" />
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            htmlFor="product-select"
            className="block text-sm font-bold mb-2"
          >
            You have been invited by someone?:
          </label>
          <input
            type="text"
            id="inviterName"
            placeholder="User Name of the one who invited you"
            value={user.inviterName ?? ""}
            onChange={(e) => setUser({ ...user, inviterName: e.target.value })}
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
          {!isInviterValid && user.inviterName && (
            <p className="text-red-600 text-sm mt-2">
              Inviter username is not valid.
            </p>
          )}
        </div>
      </div>
      <div className="flex gap-2">
        <PrimaryButton onClick={updateUserData} disabled={!isFormValid}>
          Confirm
        </PrimaryButton>
        <PrimaryButton onClick={() => navigate("/how-it-works-creator")}>
          Finish Later
        </PrimaryButton>
      </div>
    </div>
  );
};

export default UserProfileCreation;
