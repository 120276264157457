import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";

export const callContactMessages = async (
  email: string,
  name: string,
  message: string
) => {
  const contactMailer = httpsCallable(functions, "contactMailer");

  try {
    const result = await contactMailer({ email, name, message });
    console.log("Result from function:", result);
    console.log("Data from function:", result.data); // Process the response
    return result.data;
  } catch (error) {
    console.error("Error calling Twitch exchange function:", error);
  }
};
