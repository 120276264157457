// src/components/SignUp.js
import { useState } from "react";
import { auth, db } from "../../firebaseConfig";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import { doc, setDoc } from "firebase/firestore";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isCreator, setIsCreator] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const signUp = async () => {
    try {
      setIsLoading(true);

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await sendEmailVerification(userCredential.user);

      // Define initial user data based on the type
      const initialUserData = {
        role: isCreator ? "creator" : "brand",
        // Add other default fields as necessary
      };

      // Create a user document in Firestore
      await setDoc(doc(db, "users", userCredential.user.uid), initialUserData);
      // await updateProfile(userCredential.user);
      if (isCreator) {
        setIsLoading(false);

        navigate("/user-profile-creation");
      } else {
        setIsLoading(false);

        navigate("/brand-profile-creation");
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container m-auto mt-4 p-4 flex flex-row items-center justify-center ">
      {isLoading && <LoadingSpinner />}

      <div className="w-1/2 bg-backgroundSecondary rounded-lg shadow-lg p-6">
        <h2 className="text-xl  font-bold mb-4">Sign Up</h2>
        {error && <p className="text-red-500">{error}</p>}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label className="block text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-6 flex justify-center">
          <label className="flex items-center cursor-pointer">
            <span className="mr-3 font-medium">Creator Account</span>
            {/* Toggle switch container */}
            <div className="relative">
              {/* Hidden checkbox */}
              <input
                type="checkbox"
                className="hidden"
                onChange={() => setIsCreator(!isCreator)}
              />
              {/* Switch background */}
              <div
                className={`block bg-gray-200 h-6 w-11 rounded-full transition ${
                  !isCreator ? "bg-secondary" : "bg-primary"
                }`}
              ></div>
              {/* Switch handle */}
              <div
                className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition transform ${
                  !isCreator ? "translate-x-5" : ""
                }`}
              ></div>
            </div>
            <span className="ml-3 font-medium">Brand Account</span>
          </label>
        </div>

        <div className="flex justify-end mt-4">
          {isCreator ? (
            <PrimaryButton onClick={() => signUp()}>
              Sign Up as Creator
            </PrimaryButton>
          ) : (
            <SecondaryButton onClick={() => signUp()}>
              Sign Up as Brand
            </SecondaryButton>
          )}
        </div>
      </div>
      <div className="w-1/2 p-4">
        <h1 className="text-xl  font-bold mb-4">Why Sign Up?</h1>
        <p className=" mb-4">
          Exclusive Access to Brand Deals: Be the first to know about new
          sponsorship opportunities tailored to your content.
        </p>
        <p className="">
          Grow Your Audience: Partner with brands that align with your values
          and attract more viewers to your channel.
        </p>
      </div>
    </div>
  );
};

export default SignUp;
