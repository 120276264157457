import axios from "axios";

export const fetchTwitchUserData = async (accessToken: any) => {
  console.log("accessToken", accessToken);

  try {
    const response = await axios.get("https://api.twitch.tv/helix/users", {
      headers: {
        "Client-Id": process.env.REACT_APP_TWITCH_CLIENT_ID, // Replace with your Twitch Client ID
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data.data[0]; // This should contain the user's data
  } catch (error) {
    console.error("Error fetching Twitch user data:", error);
    throw error;
  }
};
