import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../Routing/PrivateRoute";
import Dashboard from "../../modules/Dashboard/Dashboard";
import LandingPage from "../../modules/LandingPage/LandingPage";
import SignIn from "../../modules/SignIn/SignIn";
import SignUp from "../../modules/SignUp/SignUp";
import UserProfileCreation from "../../modules/SignUp/user-profile-creation/UserProfileCreation";
import BrandProfileCreation from "../../modules/SignUp/brand-profile-creation/BrandProfileCreation";
import CreatorSearch from "../../modules/Creators/CreatorSearch/CreatorSearch";
import Profile from "../../modules/Profile/Profile";
import BrandSearch from "../../modules/BrandSearch/BrandSearch";
import SuccessStories from "../../modules/Creators/SuccessStories/SuccessStories";
import Chats from "../../modules/Chat/Chats/Chats";
import ChatRoom from "../../modules/Chat/Chatroom/ChatRoom";
import Payment from "../../modules/Payment/Payment";
import Account from "../../modules/Profile/Account/Account";
import ProfileView from "../../modules/ProfileView/ProfileView";
import { FC } from "react";
import TwitchAuth from "../../modules/Profile/utils/TwitchAuth";
import HowItWorks from "../../modules/HowItWorks/HowItWorks";
import HowItWorksCreator from "../../modules/HowItWorks/HowItWorksCreator/HowItWorksCreator";
import HowItWorksBrand from "../../modules/HowItWorks/HowItWorksBrand/HowItWorksBrand";
import Blog from "../../modules/Blog/Blog";
import Article from "../../modules/Blog/Article";
import Pricing from "../../modules/Pricing/Pricing";
import Support from "../../modules/Support/Support";
import PrivacyPolicy from "../../modules/PrivacyPolicy/PrivacyPolicy";
import About from "../../modules/About/About";
import PricingForCreators from "../../modules/Pricing/PricingCreators";
import Imprint from "../../modules/PrivacyPolicy/Imprint";

interface SponsRouterProps {
  openChatPopup: (chatId: string) => void;
}

const SponsRouter: FC<SponsRouterProps> = ({ openChatPopup }) => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/user-profile-creation" element={<UserProfileCreation />} />
      <Route
        path="/brand-profile-creation"
        element={<BrandProfileCreation />}
      />
      <Route path="/success-stories" element={<SuccessStories />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/how-it-works-creator" element={<HowItWorksCreator />} />
      <Route path="/how-it-works-brand" element={<HowItWorksBrand />} />
      <Route path="/support" element={<Support />} />
      <Route path="/pricing-for-brands" element={<Pricing />} />
      <Route path="/pricing-for-creators" element={<PricingForCreators />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/imprint" element={<Imprint />} />
      <Route path="/we" element={<About />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:articleId" element={<Article />} />

      <Route
        path="/fresh-talents"
        element={
          <PrivateRoute brandRoute={true}>
            <CreatorSearch />
          </PrivateRoute>
        }
      />
      <Route
        path="/creator-profile/:creatorId"
        element={
          <PrivateRoute>
            <ProfileView />
          </PrivateRoute>
        }
      />

      <Route
        path="/chats"
        element={
          <PrivateRoute>
            <Chats openChatPopup={openChatPopup} />
          </PrivateRoute>
        }
      />
      <Route path="/chat/:chatId" element={<ChatRoom />} />

      <Route
        path="/brands"
        element={
          <PrivateRoute>
            <BrandSearch />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment"
        element={
          <PrivateRoute>
            <Payment />
          </PrivateRoute>
        }
      />

      <Route
        path="/account"
        element={
          // <PrivateRoute>
          <Account />
          // {/* </PrivateRoute> */}
        }
      />

      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/twitch-auth"
        element={
          <PrivateRoute>
            <TwitchAuth />
          </PrivateRoute>
        }
      />

      <Route path="/" element={<LandingPage />} />

      {/* <Route path="/confessions/:confessionId" element={<ConfessionDetail />} />
          <Route path="/user/:userId" element={<UserDetails />} />
          <Route path="/chat/:chatId" element={<ChatRoom />} />
          <Route path="/chats" element={<Chats />} />  */}
    </Routes>
  );
};

export default SponsRouter;
