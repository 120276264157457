// Function to truncate array items for display and ensure filtered item is shown
export const truncateItems = (items: any, filterTerm: any, limit = 3) => {
  if (!items) return "";

  const itemLabels = items.map((item: { label: any }) => item.label);

  if (filterTerm) {
    const filterTermLower = filterTerm.toLowerCase();
    const filterTermIndex = itemLabels.findIndex((label: string) =>
      label.toLowerCase().includes(filterTermLower)
    );

    if (filterTermIndex >= 0) {
      const newItemLabels = [...itemLabels];
      // Move the matched item to the beginning of the array
      const [matchedItem] = newItemLabels.splice(filterTermIndex, 1);
      newItemLabels.unshift(matchedItem);

      // Now truncate the array and include '...' if necessary
      return newItemLabels.length <= limit
        ? newItemLabels.join(", ")
        : `${newItemLabels.slice(0, limit).join(", ")}...`;
    }
  }

  // Normal truncation if filterTerm is not relevant or not found
  return itemLabels.length <= limit
    ? itemLabels.join(", ")
    : `${itemLabels.slice(0, limit).join(", ")}...`;
};
